import Layer from "ol/layer/Layer.js";
import WebGLVectorLayerRenderer from "ol/renderer/webgl/VectorLayer.js";

const style = {
  "stroke-color": [
    "case",
    ["==", ["get", "color"], "E3412B"],
    "#E3412B",
    ["==", ["get", "color"], "FEB743"],
    "#FEB743",
    ["==", ["get", "color"], "30C876"],
    "#30C876",
    ["==", ["get", "color"], "1E8C4D"],
    "#1E8C4D",
    ["==", ["get", "color"], "828282"],
    "#828282",
    ["==", ["get", "class"], "0"],
    "#E3412B",
    ["==", ["get", "class"], "1"],
    "#FEB743",
    ["==", ["get", "class"], "2"],
    "#30C876",
    ["==", ["get", "class"], "3"],
    "#1E8C4D",
    ["==", ["get", "class"], "4"],
    "#828282",
    ["==", ["get", "class"], "saline_soil"],
    "rgba(171, 23, 23, 1)",
    ["==", ["get", "class"], "waterlog"],
    "rgba(129, 214, 238, 1)",
    "rgba(255, 0, 64, 1)",
  ],
  "stroke-width": 1.5,
  "fill-color": [
    "case",
    ["==", ["get", "color"], "E3412B"],
    "rgba(227, 65, 43, 0.3)",
    ["==", ["get", "color"], "FEB743"],
    "rgba(254, 183, 67, 0.3)",
    ["==", ["get", "color"], "30C876"],
    "rgba(48, 200, 118, 0.3)",
    ["==", ["get", "color"], "1E8C4D"],
    "rgba(30, 140, 77, 0.3)",
    ["==", ["get", "color"], "828282"],
    "rgba(130, 130, 130, 0.3)",
    ["==", ["get", "class"], "0"],
    "rgba(227, 65, 43, 0.3)",
    ["==", ["get", "class"], "1"],
    "rgba(254, 183, 67, 0.3)",
    ["==", ["get", "class"], "2"],
    "rgba(48, 200, 118, 0.3)",
    ["==", ["get", "class"], "3"],
    "rgba(30, 140, 77, 0.3)",
    ["==", ["get", "class"], "4"],
    "rgba(130, 130, 130, 0.3)",
    ["==", ["get", "class"], "saline_soil"],
    "rgba(171, 23, 23, 0.3)",
    ["==", ["get", "class"], "waterlog"],
    "rgba(129, 214, 238, 0.5)",
    "rgba(255, 0, 64, 0.25)",
  ],
};

class WebGlLayer extends Layer {
  createRenderer() {
    return new WebGLVectorLayerRenderer(this, {
      style,
    });
  }
}

export default WebGlLayer;
